.popin-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transition: opacity 500ms;
  background: rgba(0, 0, 0, 0.7);
  padding: 0;
}
.popin {
  position: relative;
  border: 1px solid $dark-blue;
  width: 100%;
  max-width: 580px;
  max-height: 90%;
  overflow-y: scroll;
  .card-popin {
    border-bottom: 1px solid $dark-blue;
    padding: 60px 100px 50px 100px;
  }
  .action-buttons {
    display: flex;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      background-color: transparent;
      font-size: 30px;
      font-family: $OpenSans-Regular;
    }
    .action-btn {
      flex: 1;
      cursor: pointer;
      background-color: $white;
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
      transition: all ease 0.5s;
      &:nth-child(2) {
        border-right: 1px solid $dark-blue;
        color: $dark-blue;
        font-family: $FinancierDisplay-Regular;
        &:hover{
          background-color: $dark-blue;
          color: $white;
          border-top-color: $white;
        }
      }
      &:nth-child(3) {
        color: $pink;
        font-family: $FinancierDisplay-Black;
        &:hover{
          background-color: $pink;
          color: $white;
        }
      }
    }
  }
}
