.form {
  padding: 0;
  label {
    color: $dark-blue;
    font-size: 20px;
    line-height: 42px;
    font-family: $FinancierDisplay-Regular;
  }
  .form-author {
    display: flex;
    border-top: 1px solid $dark-blue;
    border-bottom: 1px solid $dark-blue;
    label {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      width: 100%;
      input {
        margin-top: 10px;
      }
      &:nth-child(1) {
        margin-left: 100px;
      }
      &:nth-child(2) {
        border-left: 1px solid $dark-blue;
        padding-left: 35px;
      }
    }
  }
  .form-message {
    display: flex;
    flex-direction: column;
    margin: 20px 100px 0 100px;
    min-height: 500px;
    max-height: 500px;
    @media screen and (max-width: $screen-max-sm) {
      min-height: unset;
      margin: 20px 100px;
    }
    textarea {
      margin-top: 30px;
      border: none;
      height: 450px;
      resize: none;
      line-height: 30px;
      @media screen and (max-width: $screen-max-sm) {
        height: 100px;
      }
    }
  }
  .form-configuration {
    display: flex;
    border-top: 1px solid $dark-blue;
    border-bottom: 1px solid $dark-blue;
    padding: 0 25px 0 45px;
    .card-color {
      flex: 1;
      padding: 15px 0 55px 0;
      &:nth-child(3) {
        border-left: 1px solid $dark-blue;
        padding: 15px 0 55px 25px;
        .radio label:nth-child(3) input {
          background-color: $dark-blue;
        }
      }
      .radio {
        display: flex;
        gap: 15px;
        margin-top: 40px;
        label {
          width: 45px;
          height: 45px;
          &:nth-child(1) input {
            border: 1px solid $dark-blue;
            background-color: $white;
          }
          &:nth-child(2) input {
            background-color: $pink;
          }
          &:nth-child(3) input {
            background-color: $blue;
          }
          &:nth-child(4) input {
            background-color: $dark-blue;
          }
          input {
            transition: 200ms ease-in-out;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            &:checked {
              box-shadow: 0 0 0 8px $checked-grey;
            }
          }
        }
      }
    }
    .card-font {
      display: flex;
      flex: 1;
      flex-direction: column;
      border-left: 1px solid $dark-blue;
      padding: 15px 0 40px 25px;
      input {
        display: none;
        &:checked + label {
          color: $pink;
        }
      }
      label {
        transition: 200ms ease-in-out;
        &:nth-child(2) {
          margin-top: 10px;
          font-family: $FinancierDisplay-Regular;
        }
        &:nth-child(4) {
          font-family: $OpenSans-Regular;
        }
        &:nth-child(6) {
          font-family: $FinancierDisplay-Black;
        }
      }
    }
    .card-text-size {
      display: flex;
      flex: 1;
      flex-direction: column;
      border-left: 1px solid $dark-blue;
      padding: 15px 0 20px 25px;
      input {
        display: none;
        &:checked + label {
          color: $pink;
        }
      }
      label {
        transition: 200ms ease-in-out;
        line-height: initial;
        &:nth-child(2) {
          margin-top: 10px;
          font-size: 20px;
          line-height: 42px;
        }
        &:nth-child(4) {
          font-size: 40px;
        }
        &:nth-child(6) {
          font-size: 60px;
        }
      }
    }
  }
  .form-btn {
    cursor: pointer;
    background-color: $pink;
    padding: 35px 10px;
    width: 100%;
    color: $white;
    font-size: 34px;
    font-family: $FinancierDisplay-Black;
    text-align: center;
    transition: all ease 0.5s;
    &:hover{
      background-color: white;
      color: #f11d58;
    }
  }

  @media screen and (max-width: $screen-max-lg) {
    .form-configuration {
      display: block;
      padding: 0 0 0 100px;
      .card-color:nth-child(1) {
        float: left;
        padding: 25px 0;
        width: 45%;
      }
      .card-font {
        float: right;
        padding: 25px 0 0 50px;
        width: 55%;
      }
      .card-color:nth-child(3) {
        float: left;
        border-left: 0;
        padding: 25px 0;
        width: 45%;
      }
      .card-text-size {
        float: right;
        padding: 25px 0 25px 50px;
        width: 55%;
      }
    }
  }

  @media screen and (max-width: $screen-max-sm) {
    .form-author {
      label {
        &:nth-child(1) {
          margin-left: 35px;
        }
      }
    }
    .form-message {
      margin-left: 35px;
    }
    .form-configuration {
      padding: 0 0 0 35px;
      .card-color:nth-child(1) {
        width: 50%;
      }
      .card-font {
        width: 50%;
      }
      .card-color:nth-child(3) {
        width: 50%;
      }
      .card-text-size {
        width: 50%;
      }
    }
  }

  @media screen and (max-width: $screen-max-xs) {
    .form-author {
      flex-direction: column;
      label {
        &:nth-child(2) {
          border-top: 1px solid $dark-blue;
          border-left: none;
        }
      }
    }
    .form-message {
      margin-right: 35px;
      line-height: initial;
    }
    .form-configuration {
      display: flex;
      flex-direction: column;
      padding: 0 35px;
      .card-color {
        &:nth-child(1),
        &:nth-child(3) {
          padding: 15px 0;
          width: 100%;
        }
        .radio {
          margin-top: 20px;
        }
      }
      .card-font {
        border-left: 0;
        padding: 15px 0;
        width: 100%;
      }
      .card-text-size {
        border-left: 0;
        padding: 15px 0;
        width: 100%;
      }
    }
  }
}
