.title {
  font-family: $FinancierDisplay-Black;
  span {
    color: $pink;
  }
}

// targeting radio button labels
label label,
input[type="radio"] {
  cursor: pointer;
  width: fit-content;
}
