/* --------------
    THE MOST USED SASS MIXINS
----------------- */

// Add vendor prefixes to any css property
@mixin css3-prefix($prop, $value) {
  -webkit-#{$prop}: #{$value};
  -moz-#{$prop}: #{$value};
  -ms-#{$prop}: #{$value};
  -o-#{$prop}: #{$value};
  #{$prop}: #{$value};
}

// Font Face Rules
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: eot woff2 woff ttf svg
) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );

  @each $ext in $exts {
    $extmod: if(
      map-has-key($extmods, $ext),
      $ext + map-get($extmods, $ext),
      $ext
    );
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + "." + $extmod)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}

// Keyframes Animations
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

// Retina Ready Images
/*
    To use this mixin, you need to provide two versions of the same image, one in single size (1x) and one in double size (2x). You can use this mixin to provide a retina-ready background image for any HTML element.

    .image {
        background: url("my-image.png") no-repeat;
        @include retina-image("my-image2x.png", 1000px, 500px);
    }
*/
@mixin retina-image($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

// Absolute Positionning
@mixin abs-position($top, $right, $bottom, $left) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

// Arrow with four optional directions
@mixin arrow($direction: down, $size: 5px, $color: #555) {
  width: 0;
  height: 0;
  @if ($direction == left) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
  } @else if ($direction == right) {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  } @else if ($direction == down) {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  } @else {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
}

// Fixed Aspect Ratio
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
    //padding-top: math.div($height, $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    // font-size: 0;
  }
}

// Text shortening
/*
    It performs truncation on any text that overflows its containing element and adds an ellipsis (…) to the end.
 */
@mixin text-shorten {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* ---------------------------------------------------------------------------------------------------------------- */
/* --------------
    OTHERS SASS MIXINS
----------------- */
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin columnCount($count, $ecart, $style, $width, $color: #000000) {
  -moz-column-count: $count;
  -moz-column-gap: $ecart;
  -moz-column-rule: $width $style;
  -webkit-column-count: $count;
  -webkit-column-gap: $ecart;
  -webkit-column-rule: $width $style;
  column-count: $count;
  column-gap: $ecart;
  column-rule: $width $style;
  column-rule-color: $color;
  -webkit-column-rule-color: $color;
  -moz-column-rule-color: $color;
}

@mixin user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  -webkit-user-drag: none;
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

@mixin line-height($fontsize, $lineheight) {
  line-height: ($lineheight) + px;
  line-height: ($lineheight/10) + rem;

  margin-top: (($lineheight - $fontsize)/2 * -1) + px;
  margin-top: ((($lineheight - $fontsize)/2 * -1) / 10) + rem;
}

/* Letter spacing is simpler.. stolen from somewhere */
@mixin letter-spacing($letterspacing) {
  letter-spacing: $letterspacing/1000 + em;
}

/* font-size to rem with backup, stolen from somewhere */
@mixin font-size($sizeValue: 16, $relative: false) {
  font-size: ($sizeValue) + px;

  @if ($relative) {
    font-size: pxToEm($sizeValue);
  } @else {
    font-size: pxToRem($sizeValue);
  }
}
