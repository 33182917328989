.buttons{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;

  .btn {
    border-radius: 27px;
    background-color: $pink;
    border: 1px solid $pink;
    padding: 15px 50px;
    color: $white;
    font-size: 21px;
    font-family: $FinancierDisplay-Black;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: all ease 0.5s;
    &:hover{
      background-color: $white;
      color: $pink;
    }
    &--posted {
      background-color: $dark-blue;
      border: 1px solid $dark-blue;
      padding: 15px 35px;
      &:hover{
        background-color: $white;
        color: $dark-blue;
      }
    }

    &.action-btn {
      display: inline-block;
    }

  }
}
