.footer{
  background-color: $white;
  border-top: 1px solid $dark-blue;
  padding: 15px 10px;
  .container{
    display: flex;
    justify-content: center;
  }
  .website-link{
    color: $dark-blue;
    font-family: $OpenSans-Regular;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      margin-right: 15px;
      width: 25px;
    }
  }
}
