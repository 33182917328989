@import "./assets/css/vendor/bootstrap-grid.min.css";
@import "./assets/css/scss/style.scss";
@import "./components";

.page-home {
  padding-top: 100px;
  .page-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $dark-blue;
    padding: 50px 25px;
    .title {
      max-width: 25ch;
      color: $dark-blue;
      font-size: 70px;
      text-align: center;
    }
    .text {
      margin-bottom: 15px;
      color: $dark-blue;
      font-size: 21px;
      line-height: 48px;
      font-family: $OpenSans-Regular;
      text-align: center;
    }
  }
  .container {
    margin-top: 40px;
  }

  @media screen and (max-width: $screen-max-sm) {
    padding-top: 75px;
    .page-header {
      padding: 30px 25px;
    }
  }
  @media screen and (max-width: $screen-max-sm) {
    .page-header {
      .title {
        font-size: 40px;
      }
      .text {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.page-message {
  padding-top: 100px;
  @media screen and (max-width: $screen-max-sm) {
    padding-top: 75px;
  }
  .container {
    border-right: 1px solid $dark-blue;
    border-left: 1px solid $dark-blue;
  }
  .title {
    padding: 60px 25px;
    color: $dark-blue;
    font-size: 84px;
    text-align: center;
    @media screen and (max-width: $screen-max-sm) {
      padding: 30px 25px;
    }
  }
  .filler-images {
    display: flex;
    padding: 0;
    height: 307px;
    background-size: contain;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: $screen-max-sm) {
    .container {
      max-width: 100%;
    }
  }

  @media screen and (max-width: $screen-max-sm) {
    .title {
      font-size: 40px;
    }
    .text {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
