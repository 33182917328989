.header {
  display: flex;
  align-items: center;
  background-color: $dark-blue;
  position: fixed;
  width: 100%;
  z-index: 10;
  .img-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border-right: 1px solid #ffffff;
    padding: 25px 0;
    height: 100%;
  }
  .title {
    flex: 3;
    margin-right: 25px;
    margin-left: 60px;
    color: $white;
    font-size: 35px;
  }

  @media screen and (max-width: $screen-max-md) {
    .title {
      margin-left: 40px;
      font-size: 28px;
    }
  }

  @media screen and (max-width: $screen-max-sm) {
    .img-container {
      padding: 25px 0;
      img{
        width: 35px;
      }
    }
    .title {
      margin-left: 25px;
      text-align: center;
      font-size: 19px;
    }
  }
}
