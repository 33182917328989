* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  text-rendering: optimizeLegibility;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  background-color: $white;
  color: $black;
  font-family: $FinancierDisplay-Regular;
}

img {
  max-width: 100%;
  height: auto;
}

input,
button {
  appearance: none;
  border: 0;
}

a {
  text-decoration: none;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
  padding: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  -webkit-font-smoothing: antialiased;
  height: auto;
}
/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}
/*
  10. Remove style ul
*/
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
